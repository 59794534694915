import React from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/sa1.jpg'
import hero2 from '../../images/slider/sa2.jpg'
import { Height } from "@material-ui/icons";

const settings = {
    dots: false,
    arrows: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2500,
    fade: true
};

const Hero = () => {
    return (
        <section className="hero hero-slider-wrapper hero-style-1">
            <div className="hero-slider">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>Ultimate POS</h5>
                                    </div>
                                    <div className="slide-title">
                                        <h2>نظام متكامل لإدارة كافة أعمالك</h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>نقدم لك نظام  متكامل يعمل على مساعدتك في إدارة كل جوانب أعمالك بواجهة سهلة الاستخدام تدعم اللغة العربية، أصدر فواتيرك وأدِر مبيعاتك ومخزونك وعملائك موظفيك وحساباتك ودورة العمل لديك، مع تطبيقات إدارة شاملة وقابلة للتخصيص لتلبية احتياجات أعمالك، حيث يُمكِنُك استخدامه في أي وقت ومن أي مكان لكونه نظامًا سحابيًا بإجراءات أمان قوية تضمن سلامة بياناتك.</p>
                                    </div>
                                    <div className="btns">
                                        <Link to="/about" className="theme-btn">اعرف المزيد</Link>
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero1} alt=""  style={{ height: '699px', width : '699px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="container">
                            <div className="row">
                                <div className="col col-lg-6 col-md-8 col-sm-12 slide-caption">
                                    <div className="slide-title-sub">
                                        <h5>Ultimate POS</h5>
                                    </div>
                                    <div className="slide-title">
                                        <h2>تطبيق صمم خصيصا لأدارة اعمالك</h2>
                                    </div>
                                    <div className="slide-subtitle">
                                        <p>هدفنا دائماّ ارضاء جميع عملائنا لذلك نقدم لهم كل ما هو يستطيع تقدم الخدمات المناسبة لهم</p>
                                    </div>
                                    <div className="btns">
                                    <Link to="/about" className="theme-btn">اعرف المزيد</Link>
                                    </div>
                                    <div className="slider-pic">
                                        <img src={hero2} alt="" style={{ height: '699px', width : '699px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    )
}

export default Hero;
import timg1 from '../images/team/avatar2.png'
import timg2 from '../images/team/avatar2.png'
import timg3 from '../images/team/avatar3.png'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'د / ادم ابراهيم',
        title:'رئيس مجلس الأدارة',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'ا / مصطفي امام',
        title:'مدير',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'ا / محمود حسين',
        title:'مسوق الكتروني',
    }
]

export default Teams;
import pimg1 from '../images/service/sa4.jpg'
import pimg2 from '../images/service/sa5.jpg'
import pimg3 from '../images/service/sa6.jpg'
import pimg4 from '../images/service/sa7.jpg'
import pimg5 from '../images/project/img-4.jpg'


import ps1img1 from '../images/project-single/1.jpg'
import ps1img2 from '../images/project-single/2.jpg'
import ps1img3 from '../images/project-single/3.jpg'
import ps1img4 from '../images/project-single/4.jpg'
import ps1img5 from '../images/project-single/1.jpg'



import psub1img1 from '../images/project-single/p1.jpg'
import psub1img2 from '../images/project-single/p2.jpg'


const Projects = [
    {
       Id:'1',
       pImg:pimg1,
       ps1img:ps1img1,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'التسويق الالكتروني',  
       subTitle:'الدعاية والاعلان',   
    },
    {
       Id:'2',
       pImg:pimg2,
       ps1img:ps1img2,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'التخطيط الاستثماري', 
       subTitle:'الخطط الاستثمارية',    
    },
    {
      Id:'3',
      pImg:pimg3,
      ps1img:ps1img3,
      psub1img1:psub1img1,
      psub1img2:psub1img2,
      title:'تحليل الاعمال',  
      subTitle:'استشارات الاعمال',   
   },
    {
       Id:'4',
       pImg:pimg4,
       ps1img:ps1img4,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'النصائح المالية',  
       subTitle:'الخبرة المالية', 
    },
    {
       Id:'5',
       pImg:pimg5,
       ps1img:ps1img5,
       psub1img1:psub1img1,
       psub1img2:psub1img2,
       title:'Market Research',  
       subTitle:'Business Consulting', 
    },
    
]

export default Projects;
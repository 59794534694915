import simg from '../images/service/sa4.jpg'
import simg2 from '../images/service/sa5.jpg'
import simg3 from '../images/service/sa6.jpg'
import simg4 from '../images/service/sa7.jpg'

import sImgS1 from '../images/service/sa4.jpg'
import sImgS2 from '../images/service/sa5.jpg'
import sImgS3 from '../images/service/sa6.jpg'
import sImgS4 from '../images/service/sa7.jpg'

import sSingleimg1 from '../images/service/sa4.jpg'
import sSingleimg2 from '../images/service/sa7.jpg'




const Services = [
    {
        Id: '1',
        sImg:simg,
        sImgS:sImgS1,
        sTitle: 'المبيعات',
        description:'الفاتورة الإلكترونية والضريبية، نقاط البيع، المتجر الإلكتروني، المبيعات المستهدفة والعمولات، الأقساط، العروض، التأمينات',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '2',
        sImg:simg2,
        sImgS:sImgS2,
        sTitle: 'المحاسبة العامة',
        description:'المالية، الحسابات العامة، قيود اليومية، دورة الشيكات، دليل الحسابات، الأصول، مراكز التكلفة،حساب الأستاذ، التقارير المالية',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '3',
        sImg:simg3,
        sImgS:sImgS3,
        sTitle: 'علاقات العملاء',
        description:'إدارة العملاء، متابعة العملاء، المواعيد، النقاط والأرصدة، العضويات والاشتراكات، حضور العملاء',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '4',
        sImg:simg4,
        sImgS:sImgS4,
        sTitle: 'المخزون',
        description:'المنتجات والخدمات، تتبع المخزون، المشتريات، الموردون، المستودعات، الجرد، قوائم الأسعار، الأذون المخزنية ',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-tax',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    }
]    

export default Services;
import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/sa3.jpg'
import shape1 from '../../images/ab-shape-1.png'
import shape2 from '../../images/ab-shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return(
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt=""/>
                                <div className="wpo-ab-shape-1"><img src={shape1} alt=""/></div>
                                <div className="wpo-ab-shape-2"><img src={shape2} alt=""/></div>
                                <div className="wpo-about-exprience">
                                    <h3>العديد من سنوات الخبرة</h3>
                                </div>
                                <div className="wpo-ab-img-2">
                                    <img src={abimg2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h4>اهلا بك في موقعنا</h4>
                            <h2>نحن شركة موثقة لادارة البرمجيات الحسابية <b>مع <span> 28+ سنة </span>من الخبرة</b>
                            </h2>
                            <p>
                            أياً كان مجال عملك سيساعدك برنامجنا في إدارته بكفاءة مصمم ليناسب مجالات العمل كافة من خلال العديد من البرامج القابلة للتخصيص بالكامل
                            </p>
                                <Link to="/about" className="theme-btn">اعرف المزيد</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;